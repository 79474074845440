import React, { Fragment } from 'react';
import {useHistory } from "react-router-dom";
import { getImgSource } from '../images';

const Background = () => {
    const history = useHistory();
    const { pathname } = history.location;
    const imgSrc = getImgSource(pathname);
  
    if (!imgSrc) return null;
  
    const { src, alt } = imgSrc;
  
    return (
      <Fragment>
        <img id="vidContainer" src={src} alt={alt} />
      </Fragment>
    )
  };

  export default Background;