import React, { useState } from 'react';
import { impactDisclaimer } from '../text';

const Impact = () => {
    const [isVisible, setIsVisible] = useState(true);
    if (!isVisible) return null;
    return (
      <div className="impact-container">
        <p>{impactDisclaimer}<span onClick={() => setIsVisible(!isVisible)} className="close-button">X</span></p>
      </div>
    )
  }

  export default Impact;