import { useEffect, useState } from "react";

import { getProducts } from "./contentful";

const promise = getProducts();

export default function useProducts() {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then(products => {
      setProducts(products);
      setLoading(false);
    });
  }, []);

  return [products, isLoading];
}
