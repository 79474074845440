import { ContentfulClient } from 'react-contentful';

const client = new ContentfulClient({
  accessToken: 'uVTG7wtnJO9Cfp2HSZq3nwaXFmMxAvCq8ORSrVM6Fck',
  space: 'jygkd2e5wnck',
});

export const getProducts = () => client.getEntries().then(response => response.items);

export const getProduct = id => {
  return client
    .getEntry(id)
    .then(response => {
      return response
    });

}
  
