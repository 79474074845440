import React, { Fragment } from 'react';
import { defaultLink } from '../App';
import Back from './Back';
import Impact from './Impact';
import { stockists } from '../text';


const Stockists = () => {
    return (
    <Fragment>
        <Back linkUrl={defaultLink} />
        <Impact />
        <div className="fixed-scroll navbar">
        <ul className="spacing blackText">
            {stockists.map((link, i) => <li key={i}><a target={link.url && "_blank"} href={link.url ? link.url : ''}>{link.name}</a></li>)}
        </ul>
        </div>
    </Fragment>
    );
}

export default Stockists;
