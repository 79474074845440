import { useEffect, useState } from "react";

import { getProduct } from "./contentful";

const useProduct = (id) => {
    const promise = getProduct(id);

    const [product, setProduct] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        promise.then(result => {
            setProduct(result);
            setLoading(false);
        });
    }, [promise]);

    return [product, isLoading];
};

export default useProduct;
