import { isMobile } from "react-device-detect";

export const HOME_BACKGROUND = { 
    src: `assets/backgrounds/HOME_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
};
export const STOCKISTS_BACKGROUND = { 
    src: `assets/backgrounds/STOCKISTS_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
};
export const INFORMATION_BACKGROUND = { 
    src: `assets/backgrounds/INFORMATION_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
}
export const ABOUT_BACKGROUND = { 
    src: `assets/backgrounds/ABOUT_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
};
export const IMPACT_BACKGROUND = { 
    src: `assets/backgrounds/IMPACT_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
};
export const CARE_BACKGROUND = { 
    src: `assets/backgrounds/CARE_BACKGROUND${ isMobile ? '_mobile' : '' }.webp`, 
    alt: 'background image' 
};

export const getImgSource = path => {
    if (path === '/stockists') return STOCKISTS_BACKGROUND;
    if (path === '/information') return INFORMATION_BACKGROUND;
    if (path === '/about') return ABOUT_BACKGROUND;
    if (path === '/impact') return IMPACT_BACKGROUND;
    if (path === '/care') return CARE_BACKGROUND;
    if (path === '/') return HOME_BACKGROUND;
  
    return null;
}
  