import React from 'react';
import ReactDOM from 'react-dom';
import PBS from './App';
import './index.css';

ReactDOM.render(
    <PBS />,
  document.getElementById('home-root')
);

