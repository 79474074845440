export const aboutText = "Canadian Jeweller Himo Martin founded Pearls Before Swine back in 2006 as a means to explore and work with defective metals and pearls in order to radicalize the fashion industry’s traditional standards of accessory products.";

export const impactText = "At the core of PBS is a commitment to taking responsibility for the brand’s contributions to an industry infamous for unethical and environmentally hazardous practices. As a result, every aspect of the brand’s environmental and ethical contribution is evaluated: metal materials are sourced from artisanal and small-scale mining operations that produce Fairtrade and Fairmined gold and silver; stones are locally sourced and cut; products are produced in limited runs, in-house by Martin and a small production team; and the brand limits carbon emissions through restricted carbon-neutral shipping methods.";

export const careText = "Because all of our products are made with carefully selected, durable precious metals and stones, your PBS piece will last you a lifetime. However, certain efforts should be considered to preserve the full luster of your jewellery. We recommend removing any jewellery before showering, swimming and use of the sauna. We also recommend you avoid wearing your jewellery while using any harsh cleaning or cosmetic products. While these activities and products won’t permanently damage your jewellery, they may contribute to oxidization or a grime build-up over time. If needed, you can quickly restore your jewellery’s shine by simply polishing it with the provided cloth bag. For more stubborn dirt, we recommend soaking your jewellery in warm water and using a soft-bristled brush to gently brush dirt away.";

export const impactDisclaimer = "This website uses default typefaces, dithered images, off-line reading options, and other tricks to lower energy use far below that of the average website";

export const stockists = [
	{ name: 'KITH', url: 'https://kith.com/' },	
	{ name: 'DPTO', url: 'https://dpto.la/search?type=product&q=pearls+before+swine' },	
	{ name: 'MR PORTER', url: 'https://www.mrporter.com/en-ca/mens/designer/pearls-before-swine' },	
	{ name: 'Selfridges', url: 'https://www.selfridges.com/CA/en/cat/pearls-before-swine/mens/' },	
	{ name: 'Boon The Shop', url: 'https://www.sivillage.com/dispctg/initBrandCtg.siv?disp_ctg_no=2011025501' },	
	{ name: 'End Clothing', url: 'https://www.endclothing.com/ca/brands/pearls-before-swine' },	
	{ name: 'les étoffes', url: 'http://lesetoffes.com/'},
	{ name: 'APOC', url: 'https://apoc-store.com/search?q=pearls+before+swine' },
	{ name: 'GRAVITY POPE', url: 'https://www.gravitypope.com/collections/pearls-before-swine' },	
	{ name: 'SSENSE[M]', url: 'https://www.ssense.com/en-ca/men' },	
	{ name: 'SSENSE[W]', url: 'https://www.ssense.com/en-ca/women' },	
	{ name: 'AUTOGRAPH', url: 'https://autographbirmingham.com/collections/pearls-before-swine' },	
	{ name: 'Harrolds', url: 'https://www.harrolds.com.au/' },
	{ name: 'I.T.', url: 'https://www.ithk.com/' },	
];

 