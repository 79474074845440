import React, { useState } from 'react';

const Back = ({ floatMenu, linkUrl }) => {
    const [quantity, ] = useState(0);
  
    const styledClass = floatMenu ? 'navbar floatMenu' : 'navbar';
  
    return (
      <div className={styledClass} style={floatMenu ? { 'zIndex': 15 } : {}}>
        <a href={linkUrl} className="spacing">back</a>
        {quantity > 0 && (
          <p>
          <a className="snipcart-checkout snipcart-summary" href="#" style={{textDecoration: "none"}}>
            <strong>Cart:</strong> <span className="snipcart-total-price"></span>
          </a>
        </p>
        )}
      </div>
    );
  }

  export default Back;