import React, { Fragment } from 'react';
import {useHistory } from "react-router-dom";
import { getTxtSource, defaultLink } from '../App';
import Back from './Back';
import Impact from './Impact';

const About = () => {
    const history = useHistory();
    const { pathname } = history.location;
    const text = getTxtSource(pathname);
  
    return (
      <Fragment>
        <Back linkUrl={defaultLink} />
        <Impact />
        <div className="fixed-scroll spacing">
          <div className='contentText'>{text}</div>
        </div>
      </Fragment>
    );
  };

  export default About;