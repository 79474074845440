import React, { Fragment } from 'react';
import LazyLoad from 'react-lazy-load';
import { defaultLink } from '../App';
import Back from './Back';
import Impact from './Impact';


// number of files in directory, all files webp and numbered
const images = [...Array(58)].map((_, i) => i);
function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

const shuffledImages = shuffle(images);
const ImagesComponent = () =>
  shuffledImages.map((image, i) => {
    return (
      <LazyLoad key={i} offsetVertical={1200}>
        <img src={process.env.PUBLIC_URL + `/assets/content/${image}.webp`} alt={`Pearls Before Swine jewelry #${i++}`} />
      </LazyLoad>
    )
  })

const Content = () => {
    return (
    <Fragment>
        <Back linkUrl={defaultLink} floatMenu={true} />
        <Impact />
        <ImagesComponent />
    </Fragment>
    );
  }

  export default Content;