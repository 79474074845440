import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div>
      <button>
        <Link style={{ textDecoration: "none" }} to="/">
          Home
        </Link>{" "}
      </button>{" "}
    </div>
  );
};
