import { useProducts } from "../hooks/";
import React from 'react';

const Products = () => {
    const [products, isLoading] = useProducts();

    
    const renderProducts = () => {
      if (isLoading) return <p>Loading...</p>;
      return products.map(product => {
        console.log(product.fields.mainImage.fields.file.url)
        return <a className="card" key={product.fields.sku} href={`/store/${product.sys.id}`}>
          <div className="card-image-wrapper" style={{  
            "height": "100%", 
            "backgroundSize": "cover",
            "backgroundPosition": "center",
            "backgroundRepeat": "no-repeat",
            "minHeight": "600px",

            "backgroundImage": `url(https:${product.fields.mainImage.fields.file.url})` 
          }}>
            <div className="card-enter">⏎</div>
            <div className="card-title"><span>{product.fields.style}</span></div>

          </div>
        </a>

      });
    };
  
    return (
        <div className='wrapper'>
            <div className='content'>
                <div className="products_container">
                {renderProducts()}
                </div>
            </div>
        </div>
    );
}
  
export default Products;