import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { NotFound } from './NotFound';
import { aboutText, impactText, careText } from './text';

import Background from './components/Background';


import Product from './components/Product';
import Home from './components/Home';
import Stockists from './components/Stockists';
import Store from './components/Store';
import About from './components/About';
import Information from './components/Information';
import Content from './components/Content';


export const defaultLink = '/';

export const getImgSource = path => {
  if (path === '/stockists') return STOCKISTS_BACKGROUND;
  if (path === '/information') return INFORMATION_BACKGROUND;
  if (path === '/about') return ABOUT_BACKGROUND;
  if (path === '/impact') return IMPACT_BACKGROUND;
  if (path === '/care') return CARE_BACKGROUND;
  if (path === '/') return HOME_BACKGROUND;

  return null;
}

export const getTxtSource = path => {
  if (path === '/about') return aboutText;
  if (path === '/care') return careText;
  if (path === '/impact') return impactText;

  return null;
}

export default function PBS() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  }

  useEffect(() => {
    const imgs = [
      `assets/backgrounds/HOME_BACKGROUND.webp`,
      `assets/backgrounds/STOCKISTS_BACKGROUND.webp`,
      `assets/backgrounds/INFORMATION_BACKGROUND.webp`,
      `assets/backgrounds/ABOUT_BACKGROUND.webp`,
      `assets/backgrounds/IMPACT_BACKGROUND.webp`,
      `assets/backgrounds/CARE_BACKGROUND.webp`,
      `assets/backgrounds/HOME_BACKGROUND_mobile.webp`,
      `assets/backgrounds/STOCKISTS_BACKGROUND_mobile.webp`,
      `assets/backgrounds/INFORMATION_BACKGROUND_mobile.webp`,
      `assets/backgrounds/ABOUT_BACKGROUND_mobile.webp`,
      `assets/backgrounds/IMPACT_BACKGROUND_mobile.webp`,
      `assets/backgrounds/CARE_BACKGROUND_mobile.webp`,
    ]
    cacheImages(imgs);
  }, []);

  if (loading) {
    return <span></span>
  }

  if (!loading) {
    return (
      <>
        <Router history={history}>
          <Background />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/care" exact component={About} />
            <Route path="/impact" exact component={About} />
            <Route path="/stockists" exact component={Stockists} />
            <Route path="/information" exact component={Information} />
            <Route path="/content" exact component={Content} />
            <Route path="/store" exact component={Store} />
            <Route path="/store/:id" component={Product} />
            <Route path='*' exact component={NotFound} />
          </Switch>
        </Router>
      </>
    );
  }

}