import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Carousel, CarouselItem } from 'react-bootstrap';

import { useProduct } from "../hooks/";
  
import Back from './Back';

const Product = () => {
    const { id } = useParams();
    const [product, isLoading] = useProduct(id);
  
    if (isLoading) return <p>Loading...</p>;
  
    const imageArray = [];
    const mobileImageArray = [
      'https:' + product.fields.mainImage.fields.file.url,
    ];
    product.fields.pictures?.forEach((picture => {
      imageArray.push('https:' + picture.fields.file.url);
    }))
    product.fields.mobilePictures?.forEach((picture => {
      mobileImageArray.push('https:' + picture.fields.file.url);
    }))
  
    const renderImages = () => {
      if (isLoading) return <p>Loading...</p>
      return (
        <Carousel indicators={false} interval={null} controls={!isMobile} className='m-carousel'>
          {imageArray.map((image, i) => {
            return (
              <CarouselItem key={image + i} className={'vh-100'}>
                <img alt={`Pearls Before Swine jewelry #${product.fields.sku}`} className="d-block w-100" style={{ height: '100vh', 'objectFit': 'cover' }} src={image} />
              </CarouselItem>
            );
          })}
        </Carousel>
      );
    }
  
    const renderMobileImages = () => {
      if (isLoading) return <p>Loading...</p>
        return (        
          <Carousel indicators={false} interval={null} controls={!isMobile} className='m-carousel'>
            {mobileImageArray.map((image, i) => {
              return (
                <CarouselItem key={image + i} className={'vh-100'}>
                  <img alt={`Pearls Before Swine jewelry #${product.fields.sku}`} className="d-block w-100" style={{ height: '100vh', 'objectFit': 'cover' }} src={image} />
                </CarouselItem>
              );
            })}
        </Carousel>
      );
    }
  
    return (
      <Fragment key={product.fields.sku}>
        <Back linkUrl={'/store'} floatMenu={true}/>
        {!isMobile && renderImages()}
        {isMobile && (
          <div 
            className='mobileProduct' 
            alt={`Pearls Before Swine jewelry #${product.fields.sku}`} 
          >
            {renderMobileImages()}
          </div>
        )}        
          <div className='dollar-container enlarge-text'>
              <h3 className='enlarge-text'>$</h3>
              <h3 className='enlarge-text'>
                {product.fields.price}.00CAD
              </h3>
          </div>
          <div className='purchase-container'>
                <button
                  className="snipcart-add-item"
                  data-item-id={product.fields.sku}
                  data-item-price={product.fields.price}
                  data-item-url="https://us-central1-pearls-api.cloudfunctions.net/Products-Response-Sanitized"
                  data-item-description={product.fields.description}
                  data-item-dimensions={JSON.stringify({ weight: 1 })}
                  data-item-image={imageArray[0]}
                  data-item-name={product.fields.style}
                  {...(product.fields.options ? {
                    'data-item-custom2-name': 'OPTIONS',
                    'data-item-custom2-options': product.fields.options[0]
                  } : {})}
                  {...(product.fields.sizes ? {
                    'data-item-custom1-name': 'SIZE', 
                    'data-item-custom1-options': product.fields.sizes[0]
                  } : {})}
                >
                  <h3 className='enlarge-text'>⏎</h3>
                </button>
              <h3 className='enlarge-text'>
                PURCHASE
              </h3>
              <div className='mobile-dollar-container'>
                  <h3 className='enlarge-text'>$</h3>
                  <h3>
                    {product.fields.price}.00CAD
                  </h3>
              </div>
          </div>
      </Fragment>
    )
  };

  export default Product;