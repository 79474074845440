import React, { Fragment } from 'react';
import Background from './Background';
import Impact from './Impact';

const Home = () => {
    return (
      <Fragment>
        <Background />
        <Impact />
        <div className="fixed-scroll navbar">
          <ul className="spacing blackText">
            <li><a href='store'>Store</a></li>
            <li><a href='about'>About</a></li>
            <li><a href='stockists'>Stockists</a></li>
            <li><a href='information'>Information</a></li>
            <li><a href='care'>Jewelry Care</a></li>
            <li><a href='content'>Content</a></li>
            <li><a href='impact'>Impact</a></li>
          </ul>
        </div>
      </Fragment>
    );
  }

  export default Home;