import React, { Fragment } from 'react';
import { defaultLink } from '../App';
import Back from './Back';
import Impact from './Impact';

const Information = () => {
      return (
        <Fragment>
          <Back linkUrl={defaultLink} />
          <Impact />
          <div className="fixed-scroll navbar">
            <ul className="spacing blackText">
              <li key="1"><a href="mailto:user@pearlsbeforeswine.com">SALES</a></li>
              <li key="2"><a href="mailto:press@pearlsbeforeswine.com">PRESS</a></li>
              <li key="3"><a href="mailto:projects@pearlsbeforeswine.com">PROJECTS</a></li>
            </ul>
          </div>
        </Fragment>
      );

  }

  export default Information;