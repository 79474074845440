import React, { Fragment } from 'react';
import Products from './Products';
import { defaultLink } from '../App';
import Back from './Back';
import Impact from './Impact';

const Store = () => {
    return (
        <Fragment>
            <Back linkUrl={defaultLink} />
            <Impact />
            <Products />
        </Fragment>
    );
  }

  export default Store;